$main-font: 'Quicksand', sans-serif;

$white: #ffffff;
$black: #000000;
$bg-color: #282c34;
$box-shadow-color: #f133450c;
$dark-box-shadow-color: #7b757517;
$timercircle-dark-box-shadow-color: #ffffff33;
$gradient-stop-1: #F13346;
$gradient-stop-2: #FB5143;
$dark-gradient-stop-1: #232526;
$dark-gradient-stop-2: #414345;
