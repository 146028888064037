@import '../../_variables';

.credits {
    &__block {
        margin: 0 auto;
        margin-top: 20px;
        padding: 0;
        text-align: center; }

    &__link {
        color: $white;
        font-weight: 700; } }
