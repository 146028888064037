@import url(https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap);

root{--text: #F13346;--box-shadow-color: rgba(241,51,69,0.0470588);--timer-background-color: #fff;--button-background-color: #fff;--button-color: #F13346;--background-gradient-stop-1: #F13346;--background-gradient-stop-2: #FB5143;--timercircle-dark-box-shadow-color: rgba(255,255,255,0.2)}[data-theme='light']{--background-gradient-stop-1: #F13346;--background-gradient-stop-2: #FB5143;--timer-background-color: #fff;--button-background-color: #fff;--button-color: #F13346;--text: #F13346;--box-shadow-color: rgba(241,51,69,0.0470588)}[data-theme='dark']{--background-gradient-stop-1: #232526;--background-gradient-stop-2: #414345;--timer-background-color: #414345;--button-background-color: #414345;--button-color: #fff;--text: #fff;--box-shadow-color: rgba(123,117,117,0.0901961);--timercircle-dark-box-shadow-color: rgba(255,255,255,0.2)}body{margin:0;padding:0;font-family:"Quicksand",sans-serif;background:linear-gradient(90deg, var(--background-gradient-stop-1) 0%, var(--background-gradient-stop-2) 100%);min-height:100vh;display:flex;flex-direction:column;align-items:center;justify-content:center;color:#fff}button{-webkit-appearance:none;-moz-appearance:none;appearance:none;box-shadow:none;background:transparent;border:0;outline:0;padding:0.8em 0.9em;margin:0.5em;border-radius:50%;background-color:var(--button-background-color);color:var(--button-color);box-shadow:0 2.8px 2.2px var(--box-shadow-color),0 6.7px 5.3px var(--box-shadow-color),0 12.5px 10px var(--box-shadow-color),0 22.3px 17.9px var(--box-shadow-color),-2px 17px 33.4px var(--box-shadow-color),1px 7px 20px 2px var(--box-shadow-color);transition:all 0.1s ease-in-out}button:hover{cursor:pointer;transform:scale(1.1)}button:disabled{opacity:0.6;pointer-events:none;box-shadow:none}button:disabled svg{color:grey}button svg{pointer-events:none}

.timer{display:flex;flex-direction:column;align-items:center;justify-content:center;min-width:350px;background-color:var(--timer-background-color);color:var(--text);padding:2.5em 2em;border-radius:50px;box-shadow:0 2.8px 2.2px rgba(0,0,0,0.034),0 6.7px 5.3px rgba(0,0,0,0.048),0 12.5px 10px rgba(0,0,0,0.06),0 22.3px 17.9px rgba(0,0,0,0.072),-2px 17px 33.4px rgba(0,0,0,0.086),1px 7px 20px 2px rgba(0,0,0,0.12)}.timer__label{z-index:3;color:#fff;font-weight:500;font-size:20px;margin-bottom:15px;line-height:1;position:relative}.timer__time{margin:0;margin-bottom:0.3em;font-size:50px;line-height:1;font-weight:400;position:relative;z-index:1;color:#fff}.timer__time::after{content:'';background:linear-gradient(90deg, var(--background-gradient-stop-1) 0%, var(--background-gradient-stop-2) 100%);width:180px;height:180px;border-radius:50%;display:block;position:absolute;top:50%;left:50%;transform:translate(-50%, -50%);z-index:-1}.timer__controllers{display:flex;align-items:center;justify-content:space-between;width:100%}.timer__maintitle{margin-top:0.5em;margin-bottom:1.5em}[data-theme='dark'] .timer__time::after{box-shadow:-1px 1px 24px 2px var(--timercircle-dark-box-shadow-color)}@media screen and (max-width: 450px){.timer{width:auto;min-width:unset;margin:0 auto;padding:2em 1.5em}}

.break{display:flex;flex-direction:column;align-items:center}.break__controls{display:flex;justify-content:space-between;align-items:center;text-align:center}.break__length{font-size:26px;line-height:1;font-weight:400;padding:0 0.4em;min-width:27px}.break__label{font-weight:600}

.session{display:flex;flex-direction:column;align-items:center}.session__controls{display:flex;justify-content:space-between;align-items:center;text-align:center}.session__length{font-size:26px;line-height:1;font-weight:400;padding:0 0.4em;min-width:27px}.session__label{font-weight:600}

.controls__wrp{display:flex;justify-content:space-between;align-items:center;margin-bottom:2em;z-index:3}.controls__wrp button{padding:1.5em 1.6em}.controls__wrp button svg{font-size:1.2em}

.theme-toggler{position:absolute;top:5vw;right:5vw;display:inline-block;width:60px;height:34px;border:4px solid white;border-radius:34px}.theme-toggler input{opacity:0;width:0;height:0}.theme-toggler input:checked+.theme-toggler__slider{background-color:var(--background-gradient-stop-2)}.theme-toggler input:checked+.theme-toggler__slider:before{transform:translateX(26px)}.theme-toggler input:focus+.theme-toggler__slider{box-shadow:0 0 1px var(--background-gradient-stop-2)}.theme-toggler__slider{position:absolute;cursor:pointer;top:0;left:0;right:0;bottom:0;background-color:var(--background-gradient-stop-2);transition:0.4s}.theme-toggler__slider:before{position:absolute;content:"";height:26px;width:26px;left:4px;bottom:4px;background-color:#fff;transition:0.4s}.theme-toggler__slider.round{border-radius:34px}.theme-toggler__slider.round:before{border-radius:50%}@media screen and (min-width: 1024px){.theme-toggler{top:2vw;right:2vw}}

.credits__block{margin:0 auto;margin-top:20px;padding:0;text-align:center}.credits__link{color:#fff;font-weight:700}

