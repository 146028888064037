@import '../../_variables';

.theme-toggler {
    position: absolute;
    top: 5vw;
    right: 5vw;
    display: inline-block;
    width: 60px;
    height: 34px;
    border: 4px solid white;
    border-radius: 34px;

    input {
        opacity: 0;
        width: 0;
        height: 0;

        &:checked + .theme-toggler__slider {
            background-color: var(--background-gradient-stop-2); }

        &:checked + .theme-toggler__slider:before {
            transform: translateX(26px); }

        &:focus + .theme-toggler__slider {
            box-shadow: 0 0 1px var(--background-gradient-stop-2); } }

    &__slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: var(--background-gradient-stop-2);
        transition: 0.4s;

        &:before {
            position: absolute;
            content: "";
            height: 26px;
            width: 26px;
            left: 4px;
            bottom: 4px;
            background-color: $white;
            transition: 0.4s; }

        &.round {
            border-radius: 34px;

            &:before {
                border-radius: 50%; } } } }

@media screen and (min-width: 1024px) {
    .theme-toggler {
        top: 2vw;
        right: 2vw; } }
